<script>
import {h} from "vue";
const NodeContent = {
  name: 'node-content',
  props: ['node'],
  render() {
    const node = this.node
    const vm = this.node.tree.vm

    if (node.isEditing) {
      let nodeText = node.text

      this.$nextTick(_ => {
        this.$refs.editCtrl.focus()
      })

      return h('input', {
        domProps: {
          value: node.text,
          type: 'text'
        },
        class: 'tree-input',
        on: {
          input(e) {
            nodeText = e.target.value
          },
          blur() {
            node.stopEditing(nodeText)
          },
          keyup(e) {
            if (e.keyCode === 13) {
              node.stopEditing(nodeText)
            }
          },
          mouseup(e) {
            e.stopPropagation()
          }
        },
        ref: 'editCtrl'
      })
    }


    if (vm.$slots.default) {
      return vm.$slots.default({node: this.node})
    }

    return h('span', node.text)
  }
}

export default NodeContent
</script>
