/*import TreeRoot from "./components/TreeRoot.vue";

const install = (app) => {
    app.use(TreeRoot)
}

export default install*/

import TreeRoot from "./components/TreeRoot.vue";

TreeRoot.install = (app) => {
    app.component(TreeRoot.name, TreeRoot)
}

export default TreeRoot